import { Tileset } from '../tileset/tileset.models';
import { Location } from './location.model';
import { ScandataFile } from './scandata-file.model';

export interface ScandataModel {
  id: string;
  hasPath: boolean;
  name: string;
  connectScanFileId?: string;
  captureDatetimeUtc?: Date;
  files: ScandataFile[];
  pointCount: number;
  isClassified: boolean;
  numberOfPanoramas: number;
  scannerType?: string;
  classStatistics: { [key: number]: number };
  notes?: string;
  location?: Location;

  //value in square meters (M2)
  area?: number;

  // Lazy Loaded properties
  tilesets?: Tileset[];

  // Figma design
  thumbnailUrl?: string;
  previewUrl?: string;

  /** Status returned by API */
  status: PointcloudAPIStatus;

  /** Simplified list of Status, mapped from Status returned by API (used for display and in filters panel) */
  pointcloudStatus: PointcloudStatus;

  uploadedBy?: string;
  uploadedDate?: Date;
  fileSizeGB?: number;
  tags?: string[];

  // Transient properties (view)

  /** The model was checked / selected in the 1D list.
   *  Only selected models will display in the Connect 3D extension tree
   *  Indicates selection in 2D map view
   *  Single selection will display model detail the property panel for both the 1D list in explorer and the 3D trees
   *  Multiple selection will display information for all the selected models */
  selected: boolean;

  /** Model has children and the view is expanded to load and display these children in the 3D tree */
  expanded: boolean;

  /** User chooses to show model in the scene */
  showInScene: boolean;

  /** Model has different load states for tilesets */
  loadStatus: ScandataLoadStatus;

  /** Model has different display states for tilesets */
  displayStatus: ScandataDisplayStatus;

  /** Station icons are displayed */
  stationIconsDisplayed: boolean;

  /** All lazy properties loaded from api (used in properties panel in 1D and 3D view) */
  fullyLoaded: boolean;

  /** Transient properties related to 2D Map */
  hiddenOnMap: boolean;

  /** Show model with classification point color type */
  showClassification: boolean;

  /** Enable eye dome lighting for point cloud  */
  eyeDomeLighting: boolean;

  /** Point size for point cloud */
  pointSize?: number;

  /** Web3d model id */
  web3dId: string;
}

export enum ScandataLoadStatus {
  NotLoaded = 'NotLoaded',
  Loading = 'Loading',
  LoadingError = 'LoadingError',
  Loaded = 'Loaded',
}

export enum ScandataDisplayStatus {
  Hidden = 'Hidden',
  AwaitingDisplay = 'AwaitingDisplay',
  Displayed = 'Displayed',
  DisplayError = 'DisplayError',
  AwaitingHide = 'AwaitingHide',
}

export interface UpdateScandataModel {
  name?: string;
  captureDatetimeUtc?: Date | string;
  scannerType?: string;
  notes?: string;
  tags?: string[];
}

export enum PointcloudAPIStatus {
  Initializing = 'Initializing',
  Uploaded = 'Uploaded',
  InProgress = 'InProgress',
  Ready = 'Ready',
  Failed = 'Failed',
}

export enum PointcloudStatus {
  Processing = 'Processing',
  Ready = 'Ready',
  Failed = 'Failed',
}
