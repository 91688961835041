//Support generic SimpleChange<T> for use in ngOnChanges
//https://github.com/angular/angular/issues/17560#issuecomment-624161007

import { SimpleChange, SimpleChanges } from '@angular/core';

export type SimpleChangeTyped<T> = Omit<SimpleChange, 'previousValue' | 'currentValue'> & {
  previousValue: T;
  currentValue: T;
};

export type SimpleChangesTyped<T> = {
  [K in keyof T]: SimpleChangeTyped<T[K]>;
} & SimpleChanges;
