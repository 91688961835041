import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  Cartesian2,
  Cartesian3,
  Cesium3DTileset,
  Color,
  Matrix4,
  Quaternion,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Viewer,
} from '@cesiumgs/cesium-analytics';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, Select, Store, ofActionDispatched } from '@ngxs/store';
import { Observable, lastValueFrom, switchMap, take } from 'rxjs';
import { UpdateScandataModels } from '../../scandata/scandata.actions';
import {
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../../scandata/scandata.models';
import { ScandataService } from '../../scandata/scandata.service';
import { ScandataState } from '../../scandata/scandata.state';
import { Tileset, TilesetStatus } from '../../tileset/tileset.models';
import {
  AddPoint,
  CameraToConsole,
  ClearTrack,
  Duration,
  RemoveLastPoint,
  SaveAllTracksToLocalstorage,
  SetPath,
  StartPath,
  TrackToConsole,
} from '../scan-viewer.actions';
import { Path, Segment, getPaths } from './paths';

function MulQuatCart(l: Quaternion, r: Cartesian3) {
  const w = -l.x * r.x - l.y * r.y - l.z * r.z;
  const x = l.w * r.x + l.y * r.z - l.z * r.y;
  const y = l.w * r.y + l.z * r.x - l.x * r.z;
  const z = l.w * r.z + l.x * r.y - l.y * r.x;

  return new Quaternion(x, y, z, w);
}

function DegreesToRadians(deg: number) {
  return deg * (Math.PI / 180.0);
}

function RotateVector(x: Cartesian3, angle: number, axis: Cartesian3) {
  const sinHalfAngle = Math.sin(DegreesToRadians(angle / 2));
  const cosHalfAngle = Math.cos(DegreesToRadians(angle / 2));

  const rx = axis.x * sinHalfAngle;
  const ry = axis.y * sinHalfAngle;
  const rz = axis.z * sinHalfAngle;
  const rw = cosHalfAngle;

  const rotation = new Quaternion(rx, ry, rz, rw);
  const conjugate = Quaternion.conjugate(rotation, new Quaternion());

  const w = Quaternion.multiply(MulQuatCart(rotation, x), conjugate, new Quaternion());

  const z = new Cartesian3();
  z.x = w.x;
  z.y = w.y;
  z.z = w.z;

  return z;
}

class NewCamera {
  private zAxis = new Cartesian3(0, 0, 1);
  public Forward = new Cartesian3(0, 1, 0);
  public Up = new Cartesian3(0, 0, 13);
  public Position = new Cartesian3(0, 0, 13);
  public Scroll = 0;
  public Sensitivity = 5;
  public Pitch = 0;
  public Yaw = 0;

  constructor(private viewer: Viewer) {}

  public RotateY(angle: number) {
    this.Pitch += angle;
    let Haxis = Cartesian3.cross(this.zAxis, this.Forward, new Cartesian3());
    Haxis = Cartesian3.normalize(Haxis, new Cartesian3());

    this.Forward = RotateVector(this.Forward, angle, this.zAxis);
    Cartesian3.normalize(this.Forward, new Cartesian3());

    this.Up = Cartesian3.cross(this.Forward, Haxis, new Cartesian3());
    Cartesian3.normalize(this.Up, new Cartesian3());
  }

  public RotateX(angle: number) {
    this.Yaw += angle;
    let Haxis = Cartesian3.cross(this.zAxis, this.Forward, new Cartesian3());
    Haxis = Cartesian3.normalize(Haxis, new Cartesian3());

    this.Forward = RotateVector(this.Forward, angle, Haxis);
    Cartesian3.normalize(this.Forward, new Cartesian3());

    this.Up = Cartesian3.cross(this.Forward, Haxis, new Cartesian3());
    Cartesian3.normalize(this.Up, new Cartesian3());
  }

  public GetLeft() {
    let left = Cartesian3.cross(this.Forward, this.Up, new Cartesian3());
    left = Cartesian3.normalize(left, new Cartesian3());
    return left;
  }

  public GetRight() {
    let right = Cartesian3.cross(this.Up, this.Forward, new Cartesian3());
    right = Cartesian3.normalize(right, new Cartesian3());
    return right;
  }

  public Move(dir: Cartesian3, amt: number) {
    this.Position = Cartesian3.add(
      this.Position,
      Cartesian3.multiplyByScalar(dir, amt, new Cartesian3()),
      new Cartesian3()
    );
  }

  public async inputMouse(event: MouseEvent) {
    if (event.buttons == 1) {
      if (event.movementX != 0) {
        this.RotateY(-DegreesToRadians(event.movementX * this.Sensitivity));
      }
      if (event.movementY != 0) {
        this.RotateX(DegreesToRadians(event.movementY * this.Sensitivity));
      }
      this.viewer.camera.direction = this.Forward;
      this.viewer.camera.up = this.Up;
      this.viewer.camera.position = this.Position;
    }
  }

  public inputKeyboard(event: KeyboardEvent) {
    const delta = 1;
    const movAmt = event.shiftKey ? 10 * delta : 2 * delta;
    const at = Cartesian3.clone(this.Forward);
    const up = Cartesian3.clone(this.Up);
    if (event.key == 'c') {
      this.Move(Cartesian3.negate(up, new Cartesian3()), movAmt);
    }

    if (event.key == ' ') {
      this.Move(up, movAmt);
    }

    if (event.key == 'w') {
      this.Move(at, movAmt);
    }

    if (event.key == 'a') {
      this.Move(this.GetRight(), movAmt);
    }

    if (event.key == 's') {
      this.Move(at, -movAmt);
    }

    if (event.key == 'd') {
      this.Move(this.GetLeft(), movAmt);
    }

    this.viewer.camera.direction = this.Forward;
    this.viewer.camera.up = this.Up;
    this.viewer.camera.position = this.Position;
  }
}

@UntilDestroy()
@Component({
  selector: 'sd-viewer-web3d',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './viewer-web3d.component.html',
  styleUrls: ['./viewer-web3d.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Web3dViewerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('viewerHost', { static: false }) private viewerHost!: ElementRef;
  @Select(ScandataState.scandata) scandata$!: Observable<ScandataModel[]>;

  private viewer!: Viewer;
  private pointBudget = 30000000;
  private paths = getPaths();
  private startTime!: Date;
  private endTime!: Date;
  private map: Map<string, Cesium3DTileset> = new Map();
  private mycam!: NewCamera;
  private buildingTrack: Path = { id: 'untitled', name: 'untitled', segments: [] };
  private duration = 0;

  constructor(
    private renderer: Renderer2,
    private scandataService: ScandataService,
    private store: Store,
    private actions$: Actions
  ) {}

  async ngAfterViewInit() {
    this.viewer = this.createViewer();
    this.mycam = new NewCamera(this.viewer);

    this.setGlobeShow(false);
    //this.renderer.appendChild(this.viewerHost.nativeElement, this.viewer);

    //this.enableStationIconPicking();
    this.loadScandata();
    //this.subscribeToCurrentStation();
    //this.setPotreeQualitySettings();
    this.subscribeToActions();

    document.addEventListener('keydown', async (x) => this.mycam.inputKeyboard(x));
    // document.addEventListener('mousemove', async (x) => await this.mycam.inputMouse(x));

    let leftDown = false;

    this.viewer.screenSpaceEventHandler.setInputAction(
      (event: ScreenSpaceEventHandler.MotionEvent): void => {
        const delta = Cartesian2.subtract(event.startPosition, event.endPosition, new Cartesian2());

        const x = new MouseEvent('mousemove', {
          movementX: delta.x,
          movementY: delta.y,
          buttons: leftDown ? 1 : 0,
        });
        this.mycam.inputMouse(x);
      },
      ScreenSpaceEventType.MOUSE_MOVE
    );

    this.viewer.screenSpaceEventHandler.setInputAction(
      (event: ScreenSpaceEventHandler.PositionedEvent): void => {
        leftDown = true;
      },
      ScreenSpaceEventType.LEFT_DOWN
    );

    this.viewer.screenSpaceEventHandler.setInputAction(
      (event: ScreenSpaceEventHandler.PositionedEvent): void => {
        leftDown = false;
      },
      ScreenSpaceEventType.LEFT_UP
    );
  }

  async ngOnDestroy(): Promise<void> {
    return lastValueFrom(
      this.scandata$.pipe(
        take(1),
        switchMap((models) => this.unload(models))
      )
    );
  }

  private createViewer(): Viewer {
    const viewer = new Viewer('cesiumContainer', {
      creditViewport: 'cesiumCreditViewport',
      creditContainer: 'cesiumCreditContainer',
      baseLayerPicker: false,
      sceneModePicker: false,
      animation: false,
      navigationHelpButton: false,
      fullscreenButton: false,
      timeline: false,
      infoBox: false,
      homeButton: false,
      geocoder: false,
      selectionIndicator: false,
      scene3DOnly: true,
      requestRenderMode: true, // setup Cesium to request rendering,
      maximumRenderTimeChange: Infinity, // don't trigger a timestep for no reason.
      showRenderLoopErrors: false,
      //skyBox: false,
    });

    //viewer.scene.imageryLayers.removeAll(); // start with a blue sphere
    viewer.scene.rethrowRenderErrors = true;
    viewer.scene.globe.tileCacheSize = 2000;
    viewer.scene.globe.preloadAncestors = false;
    viewer.scene.screenSpaceCameraController.enableLook = false;
    viewer.scene.screenSpaceCameraController.enableInputs = false;
    viewer.scene.screenSpaceCameraController.enableRotate = false;
    viewer.scene.screenSpaceCameraController.enableTilt = false;
    viewer.scene.screenSpaceCameraController.enableZoom = false;
    viewer.scene.screenSpaceCameraController.enableTranslate = false;
    viewer.scene.screenSpaceCameraController.maximumZoomDistance =
      viewer.scene.globe.ellipsoid.maximumRadius * 10;
    viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.RIGHT_DOWN);
    viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.RIGHT_UP);

    return viewer;
  }

  private setGlobeShow(show: boolean) {
    if (this.viewer.scene.skyAtmosphere.show !== show) {
      this.viewer.scene.globe.translucency.enabled = !show;
      this.viewer.scene.globe.translucency.frontFaceAlpha = show ? 1 : 0;
      this.viewer.scene.globe.translucency.backFaceAlpha = show ? 1 : 0;
      this.viewer.scene.globe.undergroundColor = new Color(0.86, 0.86, 0.86);
      this.viewer.scene.skyAtmosphere.show = show;
      this.viewer.scene.sun.show = show;
      this.viewer.scene.skyBox.show = show;
      this.viewer.scene.moon.show = show;
      this.viewer.scene.backgroundColor = new Color(0.86, 0.86, 0.86);
    }
  }

  private loadScandata() {
    this.scandata$.pipe(untilDestroyed(this)).subscribe(async (scandata) => {
      // We are awaiting here because we want to use fitToView to display all loaded models or stations
      await this.updateDisplayedModels(scandata);
    });
  }

  private async updateDisplayedModels(scandata: ScandataModel[]) {
    const modelsToUnload = scandata.filter(
      (x) => x.displayStatus === ScandataDisplayStatus.AwaitingHide
    );

    const modelsToLoad = scandata.filter(
      (x) =>
        x.showInScene &&
        x.loadStatus === ScandataLoadStatus.Loaded &&
        x.displayStatus === ScandataDisplayStatus.Hidden
    );

    if (modelsToUnload.length > 0) {
      await this.unloadModels(modelsToUnload);
    }

    if (modelsToLoad.length > 0) {
      await this.loadModels(modelsToLoad);
    }

    // LM:  Only fit to view if new model was added
    if (modelsToLoad.length > 0) {
      //await this.viewer.camera.fitToView();
    }
  }

  private async unloadModels(models: ScandataModel[]) {
    const tilesets = models
      .reduce<Tileset[]>((acc, value) => acc.concat(value.tilesets ?? []), [])
      .filter((x) => x?.rootUrl);

    for (const tileset of tilesets) {
      const cesiumTileset = this.map.get(tileset.id);
      this.viewer.scene.primitives.remove(cesiumTileset);
      this.map.delete(tileset.id);
      this.viewer.scene.requestRender();
    }

    this.scandataService.setDisplayStatus(models, ScandataDisplayStatus.Hidden);
  }

  private async loadModels(models: ScandataModel[]) {
    this.scandataService.setDisplayStatus(models, ScandataDisplayStatus.AwaitingDisplay);

    for (const model of models) {
      const tileset = model.tilesets?.find(
        (t) => t.status === TilesetStatus.Ready && t.rootUrl && t.format === '3dtiles'
      );

      if (!tileset?.rootUrl) continue;

      this.startLog('Loading ' + model.name + ' into scene started' + ' ' + tileset.rootUrl);

      const cesiumTileset = new Cesium3DTileset({
        //url: 'https://do-ro-us1.stage.dataocean.trimblecloud.com/e/de0d2e16-46bb-42ef-b8b7-c51b7758dd58.1/root/tileset.json',
        url: tileset.rootUrl,
        //url: 'https://srtdevaue.blob.core.windows.net/c89bf2ac-a38b-4711-8944-ed745af4060e/pointclouds/238b2460-e6b4-4b05-8edf-62828a70f775/tilesets/cesium-tiler/tileset.json?sv=2020-02-10&st=2023-12-14T11%3A46%3A09Z&se=2024-01-15T11%3A46%3A00Z&sr=d&sp=rl&sig=40vqwae0CExneMO%2FEIWHStw5Gb5WclA%2BXGABBKkB1u4%3D&sdd=4',
        pointCloudShading: {
          attenuation: true,
          eyeDomeLighting: true,
        },
      });
      this.viewer.scene.primitives.add(cesiumTileset);

      cesiumTileset.readyPromise.then((ts) => {
        this.buildingTrack.id = model.id;
        this.buildingTrack.name = model.name;

        (<any>document).getElementById('logs').innerText +=
          'Loading ' + model.name + ' into scene ended\n';
        this.endLog('Loading ' + model.name + ' into scene ended');

        this.map.set(tileset.id, cesiumTileset);

        const rootTransform = ts.root.transform;
        const modelMatrix = ts.modelMatrix;
        console.log(rootTransform);
        // if (!Matrix4.equals(rootTransform, Matrix4.IDENTITY)) {
        //   // Already geolocated - tileset has a root transform - swap to the modelMatrix
        Matrix4.clone(rootTransform, modelMatrix);
        Matrix4.clone(Matrix4.IDENTITY, rootTransform);

        //   const matrix3 = Matrix4.getMatrix3(modelMatrix, new Matrix3());
        //   const quaternion = Quaternion.fromRotationMatrix(matrix3);
        //   const hpr = HeadingPitchRoll.fromQuaternion(quaternion);
        //   console.log('hpr', hpr);
        // } else {
        // const position = Cartesian3.fromRadians(0, 0, 13.0352 / 2);
        // ts.modelMatrix = Transforms.headingPitchRollToFixedFrame(
        //   position,
        //   new HeadingPitchRoll((147.308022 / 180) * Math.PI, 0, Math.PI / 2)
        // );
        //}
        // ts.modelMatrix = Matrix4.setTranslation(
        //   modelMatrix,
        //   Cartesian3.fromRadians(0, 0, 0),
        //   new Matrix4()
        // );

        //this.viewer.camera.flyToBoundingSphere(ts.boundingSphere);

        const path = this.paths.find((x) => x.id === model.id);
        if (path) {
          this.moveCameraForSegment(path.segments[1], path.segments[1 + 1], 0.001);
          this.mycam.Move(this.mycam.Forward, 0.1);
          this.mycam.RotateX(DegreesToRadians(1));
        } else {
          this.viewer.camera.position = ts.root.boundingSphere.center;
          this.mycam.Position = ts.root.boundingSphere.center;
          this.mycam.RotateX(DegreesToRadians(1));
        }

        // this.viewer.camera.flyTo({
        //   destination: new Cartesian3(
        //     // 6378148.8898 - 7.9142,
        //     // -2.892769112,
        //     // -18.77697112
        //     6378137.0 + 59.953925931574396 - 7.9142,
        //     133.4758438403404 - 2.892769112,
        //     -111.82594384034039 - 18.77697112
        //   ),
        //   orientation: {
        //     heading: 2 * Math.PI - 0.7853981633974483,
        //     pitch: 1.276547601689627 - Math.PI / 2,
        //     roll: 0,
        //   },
        //   duration: 0,
        // });
      });

      const success = true;

      model.displayStatus = success
        ? ScandataDisplayStatus.Displayed
        : ScandataDisplayStatus.DisplayError;
    }

    if (models.length > 0) {
      this.store.dispatch(new UpdateScandataModels(models));
    }
  }

  private async unload(models: ScandataModel[]) {
    await this.unloadModels(models);
  }

  private subscribeToActions() {
    this.actions$.pipe(ofActionDispatched(StartPath)).subscribe((action) => {
      const path = this.paths.find((x) => x.id === action.id);
      if (!path) return;

      let timeout = 0;
      for (let i = 0; i < path.segments.length; i++) {
        this.moveCameraForSegment(path.segments[i], path.segments[i], timeout);

        const current = timeout + path.segments[i].time;
        timeout += path.segments[i].time;

        setTimeout(() => {
          this.log(
            `Segment ${i} ended (duration: ${path.segments[i].time}ms, total: ${current}ms)`
          );
          (<any>document).getElementById(
            'logs'
          ).innerText += `Segment ${i} ended (duration: ${path.segments[i].time}ms, total: ${current}ms)\n`;
        }, timeout);
      }
    });

    this.actions$.pipe(ofActionDispatched(SetPath)).subscribe(() => {
      this.paths = getPaths();

      (<any>document).getElementById('logs').innerText += 'Uploaded tracks\n';
    });

    this.actions$.pipe(ofActionDispatched(CameraToConsole)).subscribe(() => {
      const camera = this.viewer.camera;
      //camera.lookLeft(3.14159);
      console.log(
        JSON.stringify({
          positionWC: camera.positionWC,
          heading: camera.heading,
          pitch: camera.pitch,
          roll: camera.roll,
          transform: camera.transform,
        })
      );
    });

    this.actions$.pipe(ofActionDispatched(AddPoint)).subscribe(() => {
      this.buildingTrack.segments.push({
        time: parseInt((<any>document).getElementById('duration').value),
        position: this.mycam.Position,
        forward: this.mycam.Forward,
        up: this.mycam.Up,
        rot: {
          pitch: this.mycam.Pitch,
          yaw: this.mycam.Yaw,
        },
      });
      (<any>document).getElementById('logs').innerText +=
        'Adding Point\nTotal Points: ' + this.buildingTrack.segments.length + '\n';
    });

    this.actions$.pipe(ofActionDispatched(ClearTrack)).subscribe(() => {
      this.buildingTrack.segments = [];
      (<any>document).getElementById('logs').innerText +=
        'Clearing Track Points\nTotal Points: ' + this.buildingTrack.segments.length + '\n';
    });

    this.actions$.pipe(ofActionDispatched(RemoveLastPoint)).subscribe(() => {
      this.buildingTrack.segments.pop();

      (<any>document).getElementById('logs').innerText +=
        'Removed Last Point\nTotal Points: ' + this.buildingTrack.segments.length + '\n';
    });

    this.actions$.pipe(ofActionDispatched(Duration)).subscribe((action) => {
      console.log(this.duration);

      this.duration = action.duration;
    });

    this.actions$.pipe(ofActionDispatched(TrackToConsole)).subscribe(() => {
      console.log(JSON.stringify(this.buildingTrack));
      let found = false;
      for (let i = 0; i < this.paths.length; i++) {
        const p = this.paths[i];
        if (p.id == this.buildingTrack.id) {
          this.paths[i] = this.buildingTrack;
          found = true;
          break;
        }
      }

      if (!found) {
        this.paths.push(this.buildingTrack);
      }

      localStorage.setItem('paths', JSON.stringify(this.paths));

      (<any>document).getElementById('logs').innerText += 'Saved Track\n';
    });

    this.actions$.pipe(ofActionDispatched(SaveAllTracksToLocalstorage)).subscribe(() => {
      localStorage.setItem('paths', JSON.stringify(this.paths));
    });
  }

  private moveCameraForSegment(segment: Segment, segment2: Segment, timeout: number) {
    if (segment.position) {
      //const offset = Cartesian3.fromRadians(0, 0, 0);
      const offset = Cartesian3.ZERO.clone();

      // Manual adjustment for A86 cesium tiler output
      // const pathPosition = new Cartesian3(
      //   segment.position.z + 7.9142,
      //   segment.position.x - 2.892769112,
      //   segment.position.y - 18.77697112
      // );

      // Rail cesium tiler manual adjustment
      // const pathPosition = new Cartesian3(
      //   segment.position.z - 284.7808724,
      //   3477510.062 - segment.position.x - 337.875077,
      //   5417876.193 - segment.position.y
      // );

      // const pathPosition = new Cartesian3(
      //   segment.position.z,
      //   segment.position.x,
      //   segment.position.y
      // );

      const pathPosition = new Cartesian3(
        segment.position.x,
        segment.position.y,
        segment.position.z
      );

      const path2Position = new Cartesian3(
        segment.position.x,
        segment.position.y,
        segment.position.z
      );

      const position = Cartesian3.add(offset, pathPosition, new Cartesian3());

      const up = Cartesian3.clone(segment.up ?? this.mycam.Up, new Cartesian3());
      const forward = Cartesian3.clone(segment.forward ?? this.mycam.Forward, new Cartesian3());
      console.log({ up, forward });

      // let dir = new Cartesian3(0, 0, 0);
      // dir = Quaternion.computeAxis(segment.quaternion ?? Quaternion.IDENTITY, dir)

      // const upMat = Transforms.northUpEastToFixedFrame(new Cartesian3(0,0,0.5));
      // //const upMat = Transforms.northUpEastToFixedFrame(Cartesian3.fromDegrees(90,0,0));

      // let up = new Cartesian3();
      // //const up = Cartesian3.fromDegrees(0,0,90);
      // up = Matrix4.multiplyByPoint(upMat, position, new Cartesian3());

      // const rot = Quaternion.normalize(Quaternion.fromAxisAngle(new Cartesian3(1,0,0), 15), new Quaternion());
      // const rotMat = Matrix4.fromTranslationQuaternionRotationScale(new Cartesian3(0,0,0), rot, new Cartesian3(1,1,1));
      // up = Matrix4.multiplyByPoint(rotMat, up, new Cartesian3());

      // up = Cartesian3.normalize(up, new Cartesian3());

      // camera.position = position;
      // const cameraPosition = this.viewer.camera.position.clone();
      // let direction = Cartesian3.subtract(position2, cameraPosition, new Cartesian3());

      // if(segment.negate){
      //   direction = Cartesian3.negate(direction, new Cartesian3())
      // }

      // direction = Cartesian3.normalize(direction, direction);
      // this.viewer.camera.direction = direction;
      // const approxUp = Cartesian3.normalize(new Cartesian3(0.21229756274908904, 0.48993963847169913, 0.8455110262468595), new Cartesian3());

      // let right = Cartesian3.cross(direction, approxUp, new Cartesian3());
      // right = Cartesian3.normalize(right, right);
      // this.viewer.camera.right = right;

      // let up = Cartesian3.cross(right, direction, new Cartesian3());
      // up = Cartesian3.normalize(up, up);
      // camera.up = up;

      setTimeout(() => {
        // this.viewer.camera.lookAt(Cartesian3.normalize(position2, new Cartesian3()), null)
        this.mycam.Position = position;
        this.mycam.Forward = forward;
        this.mycam.Up = up;

        this.viewer.camera.flyTo({
          destination: position,
          orientation: {
            //heading: 2 * Math.PI - (segment.rotation?.yaw ?? 0),
            // heading: (segment.rotation?.yaw ?? 0) + (45 * Math.PI) / 180, //(147.308022 * Math.PI) / 180, // (segment.rotation?.yaw ?? 0) + Math.PI / 2,
            // pitch: 0, //(segment.rotation?.pitch ?? 0) - Math.PI / 2,
            // roll: -Math.PI / 2,

            // heading: (((segment.rotation?.yaw ?? 0) * 180 / Math.PI)) * (Math.PI/180),
            // pitch: (((segment.rotation?.pitch ?? 0) * 180 / Math.PI)) * (Math.PI/180),
            // roll: 0,
            direction: forward,
            up: up,
          },
          duration: segment.time / 1000.0,
        });
      }, timeout);
    }
  }

  private startLog(message: string) {
    this.startTime = new Date();

    console.log(this.startTime.toUTCString() + ': ' + message);
  }

  private endLog(message: string) {
    this.endTime = new Date();
    const timeDiff = this.endTime.getTime() - this.startTime.getTime();

    console.log(this.endTime.toUTCString() + ': ' + message + ' (duration: ' + timeDiff + 'ms)');
  }

  private log(message: string) {
    console.log(new Date().toUTCString() + ': ' + message);
  }
}
