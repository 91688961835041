import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ProjectRegion } from '../project-region/project-region.models';
import { SetProjectRegion, SetSettings } from './app.actions';
import { AppContext, AppSettings } from './app.models';

export interface AppStateModel {
  settings: AppSettings;
  context: AppContext;
  projectRegion: ProjectRegion;
}

@State<AppStateModel>({
  name: 'appState',
})
@Injectable()
export class AppState {
  @Selector() static settings(state: AppStateModel) {
    return state.settings;
  }

  @Selector() static context(state: AppStateModel) {
    return state.context;
  }

  @Selector() static projectRegion(state: AppStateModel) {
    return state.projectRegion;
  }

  @Selector() static endpoints(state: AppStateModel) {
    let endpoints = state.settings.endpoints;

    if (isDefined(state.projectRegion)) {
      endpoints = {
        ...endpoints,
        ...{
          scandata: state.projectRegion.scandata,
          features: state.projectRegion.features,
        },
      };
    }

    return endpoints;
  }

  @Action(SetSettings) setSettings(ctx: StateContext<AppStateModel>, action: SetSettings) {
    ctx.patchState({ settings: action.appSettings });
  }

  @Action(SetProjectRegion) setProjectRegion(
    ctx: StateContext<AppStateModel>,
    action: SetProjectRegion
  ) {
    ctx.patchState({ projectRegion: action.projectRegion });
  }
}
