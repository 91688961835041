<div *ngFor="let model of scandata">
  <div class="flex flex-row cursor-pointer grow py-1 hover:bg-gray-0 px-3">
    <sd-scandata-tree-icon
      [treeIcon]="getModelIcon(model)"
      (clicked)="modelIconClicked(model)"
    ></sd-scandata-tree-icon>

    <div
      class="w-full overflow-hidden text-ellipsis whitespace-nowrap ml-2 pt-0.5 text-sm"
      (click)="modelNameClicked(model)"
    >
      {{ model.name }} 
    </div>

    <i class="text-lg/5 tc-icon-play" *ngIf="ids().indexOf(model.id) >= 0" (click)="modelPlayClicked(model)"></i>
  </div>
</div>
