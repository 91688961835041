<div class="h-[25px]">
  <div *ngIf="treeIcon === treeIconType.loading; else loaded">
    <i class="text-lg/5 tc-icon-spinner-dark"></i>
  </div>

  <ng-template #loaded>
    <div [ngSwitch]="treeIcon" (click)="clicked.emit()">
      <i *ngSwitchCase="treeIconType.on" class="text-lg/5 tc-icon-eye-visibility"></i>
      <i
        *ngSwitchCase="treeIconType.off"
        class="text-lg/5 tc-icon-eye-visibility-off-download opacity-60"
      ></i>
      <i
        *ngSwitchCase="treeIconType.error"
        class="text-lg/5 tc-icon-eye-visibility-error opacity-60"
      ></i>
    </div>
  </ng-template>
</div>
