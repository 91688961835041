import { ProjectRegion } from '../project-region/project-region.models';
import { AppSettings } from './app.models';

export class SetSettings {
  static readonly type = '[AppState] SetSettings';
  constructor(public readonly appSettings: AppSettings) {}
}

export class SetProjectRegion {
  static readonly type = '[AppState] SetProjectRegion';
  constructor(public readonly projectRegion: ProjectRegion) {}
}
