export interface SelectOption<TKey> {
  key: TKey;
  displayValue: string;
}

export type SelectList<TKey> = SelectOption<TKey>[];

export function createSelectListFromStringEnum<TEnum extends { [key: string]: string }>(
  enumType: TEnum
): SelectList<string> {
  const list: SelectOption<string>[] = [];

  Object.keys(enumType).forEach((key) => {
    const displayValue = enumType[key];
    const isStringKey = isNaN(parseInt(key, 10));
    const isStringValue = typeof displayValue === 'string';

    if (!isStringKey || !isStringValue) {
      throw new Error(
        'The provided enum is not a string enum. ' +
          'Please provide a string enum to convert. ' +
          '(See: https://www.typescriptlang.org/docs/handbook/enums.html#string-enums)'
      );
    }

    list.push({ key, displayValue });
  });

  return list;
}
