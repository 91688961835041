import { isNil } from '../common';
import { Guard } from '../guard-clauses/guard';

export function range(start: number, end?: number, step?: number): Array<number> {
  if (isNil(end)) {
    if (start >= 0) {
      // [0..end]
      end = start;
      start = 0;
    } else {
      // [start..0]
      end = 0;
    }
  }

  if (isNil(step)) {
    step = start < end ? 1 : -1;
  }

  const result: number[] = [];

  if (start === end) return [start];

  if (start < end) {
    // [start..end]
    Guard.against(step <= 0, 'Step cannot be <= 0 for an ascending series.');

    for (let i = start; i <= end; i += step) {
      result.push(i);
    }
  }

  if (start > end) {
    // [end..start]
    Guard.against(step >= 0, 'Step cannot be >= 0 for a descending series.');

    for (let i = start; i >= end; i += step) {
      result.push(i);
    }
  }

  return result;
}
