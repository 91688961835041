<ng-container *ngIf="(busy$ | async) === true">
  <div class="flex flex-col grow justify-center items-center -translate-y-32">
    <mat-spinner class="m-2" color="primary" [diameter]="96"></mat-spinner>
    <label class="m-2">Signing out...</label>
  </div>
</ng-container>

<ng-container *ngIf="(busy$ | async) === false">
  <div class="flex flex-col grow justify-center items-center -translate-y-32">
    <div>You have been signed out.</div>
    <button modus-flat-button color="primary" (click)="login()">Sign in</button>
  </div>
</ng-container>
