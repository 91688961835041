import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Ion } from '@cesiumgs/cesium-analytics';

@Injectable({
  providedIn: 'root',
})
export class CesiumResolverService implements Resolve<any> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    Ion.defaultAccessToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' +
      'eyJqdGkiOiJiYWE4ODY2YS1iYjI2LTRkNWQtOGJmMy1kYzUzYjNmZjFiOWEiLCJpZCI6NT' +
      'E0LCJpYXQiOjE1MjUyNjIwODh9.qCOhsj_qGTs5PUEF1S8x5oytKtGLjCleewHbnDSEZc0';
  }
}
