import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { catchError, switchMap } from 'rxjs';
import { StartPath } from '../../scan-viewer/scan-viewer.actions';
import { PatchScandataModel } from '../../scandata/scandata.actions';
import {
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../../scandata/scandata.models';
import { TilesetStatus } from '../../tileset/tileset.models';
import { TilesetService } from '../../tileset/tileset.service';
import { ScandataTreeComponent } from '../scandata-tree/scandata-tree.component';

@UntilDestroy()
@Component({
  selector: 'sd-scandata-tree-view',
  standalone: true,
  imports: [CommonModule, ScandataTreeComponent],
  templateUrl: './scandata-tree-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeViewComponent {
  @Input() scandata!: ScandataModel[];
  @Output() modelDetailClick = new EventEmitter<ScandataModel>();

  constructor(private tilesetService: TilesetService, private store: Store) {}

  modelIconClick(model: ScandataModel) {
    const show = !model.showInScene;
    if (show) {
      this.loadModel(model);
    } else {
      this.patchModelState(model.id, {
        displayStatus: ScandataDisplayStatus.AwaitingHide,
        showInScene: false,
      });
    }
  }

  modelPlayClick(model: ScandataModel) {
    this.store.dispatch(new StartPath(model.id));
  }

  private loadModel(model: ScandataModel) {
    this.patchModelState(model.id, { loadStatus: ScandataLoadStatus.Loading });

    this.tilesetService
      .getTilesets(model.id)
      .pipe(
        switchMap((tilesets) =>
          this.patchModelState(model.id, {
            tilesets,
            loadStatus: tilesets.some((t) => t.status !== TilesetStatus.Ready)
              ? ScandataLoadStatus.LoadingError
              : ScandataLoadStatus.Loaded,
            displayStatus: ScandataDisplayStatus.Hidden,
            showInScene: true,
          })
        ),
        catchError(() =>
          this.patchModelState(model.id, {
            loadStatus: ScandataLoadStatus.LoadingError,
          })
        )
      )
      .subscribe();
  }

  private patchModelState(id: string, scandataModel: Partial<ScandataModel>) {
    return this.store.dispatch(
      new PatchScandataModel({
        ...scandataModel,
        id,
      })
    );
  }
}
