export class StartPath {
  static readonly type = '[Scandata] StartPath';
  constructor(public readonly id: string) {}
}

export class CameraToConsole {
  static readonly type = '[Scandata] CameraToConsole';
}

export class SetPath {
  static readonly type = '[Scandata] SetPath';
}

export class AddPoint {
  static readonly type = '[Scandata] AddPoint';
}

export class RemoveLastPoint {
  static readonly type = '[Scandata] RemoveLastPoint';
}

export class Duration {
  static readonly type = '[Scandata] Duration';
  constructor(public readonly duration: number) {}
}
export class ClearTrack {
  static readonly type = '[Scandata] ClearTrack';
}

export class TrackToConsole {
  static readonly type = '[Scandata] TrackToConsole';
}

export class SaveAllTracksToLocalstorage {
  static readonly type = '[Scandata] SaveAllTracksToLocalstorage';
}
