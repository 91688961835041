import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, of, switchMap } from 'rxjs';
import { SCANDATA_PROJECT_URL } from '../utils/scandata-project-url';
import { Tileset } from './tileset.models';
import { CacheTileSets, TilesetState } from './tileset.state';

@Injectable({
  providedIn: 'root',
})
export class TilesetService {
  private readonly scandataProjectUrl$ = inject(SCANDATA_PROJECT_URL);

  constructor(private store: Store, private http: HttpClient) {}

  getTilesets(pointcloudId: string): Observable<Tileset[]> {
    return this.store
      .selectOnce(TilesetState.getTilesets(pointcloudId))
      .pipe(
        switchMap((tilesets) =>
          tilesets.length > 0 ? of(tilesets) : this.fetchAndCacheTilesets(pointcloudId)
        )
      );
  }

  private fetchAndCacheTilesets(pointcloudId: string) {
    return this.scandataProjectUrl$.pipe(
      switchMap((url) => {
        return this.http.get<Tileset[]>(`${url}/pointclouds/${pointcloudId}/tilesets`);
      }),
      switchMap((tilesets) => {
        return this.store
          .dispatch(new CacheTileSets(pointcloudId, tilesets))
          .pipe(map(() => tilesets));
      })
    );
  }
}
