import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ScandataTreeViewComponent } from '../../scandata-tree/scandata-tree-view/scandata-tree-view.component';
import { ScandataModel } from '../../scandata/scandata.models';
import { ScandataState } from '../../scandata/scandata.state';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  AddPoint,
  CameraToConsole,
  ClearTrack,
  Duration,
  RemoveLastPoint,
  SaveAllTracksToLocalstorage,
  SetPath,
  TrackToConsole,
} from '../scan-viewer.actions';

@UntilDestroy()
@Component({
  selector: 'sd-viewer-list',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    ScandataTreeViewComponent,
    ScrollingModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
  ],
  templateUrl: './viewer-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerListComponent implements AfterViewInit {
  @Select(ScandataState.scandata) scandata$!: Observable<ScandataModel[]>;
  @Select(ScandataState.isLoading) isLoading$!: Observable<boolean>;

  constructor(private store: Store) {}

  ngAfterViewInit(): void {
    const store = this.store;

    (<any>document).getElementById('inputfile').addEventListener('change', (event: any) => {
      const fr = new FileReader();
      fr.onload = function () {
        localStorage.setItem('paths', fr.result as string);
        //window.location.reload()
        store.dispatch(new SetPath());
      };
      fr.readAsText(event.target.files[0]);
    });
  }

  cameraConsole() {
    this.store.dispatch(new CameraToConsole());
  }

  addPoint() {
    this.store.dispatch(new AddPoint());
  }

  removeLastPoint() {
    this.store.dispatch(new RemoveLastPoint());
  }

  updateDuration(event: any) {
    this.store.dispatch(new Duration(<number>(<any>document).getElementById('duration').value));
  }

  clearTrack() {
    this.store.dispatch(new ClearTrack());
  }

  trackToConsole() {
    this.store.dispatch(new TrackToConsole());
  }

  SaveAllTracksToLocalstorage() {
    this.store.dispatch(new SaveAllTracksToLocalstorage());
  }

  LocalStorageData() {
    const data = encodeURIComponent(localStorage.getItem('paths') ?? '[]');
    return 'data:application/octet-stream,' + data;
  }
}
