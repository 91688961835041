import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ExistingState,
  StateOperator,
  compose,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ScandataFilters } from './scandata-filters.model';
import {
  ApplyFilters,
  ClearFilters,
  PatchScandataModel,
  PatchScandataModels,
  RemoveScandataModel,
  SelectAllScandataModels,
  SelectOnlyOneScandataModel,
  SelectScandataModel,
  SetIsLoading,
  SetScandata,
  UnselectAllScandataModels,
  UnselectScandataModel,
  UpdateScandata,
  UpdateScandataModels,
} from './scandata.actions';
import { ScandataModel } from './scandata.models';

export interface ScandataStateModel {
  scandata: ScandataModel[];
  isLoading: boolean;
  scandataFilters: ScandataFilters;
}

const defaultState: ScandataStateModel = {
  scandata: [],
  isLoading: false,
  scandataFilters: {},
};

@State<ScandataStateModel>({
  name: 'scandataState',
  defaults: defaultState,
})
@Injectable()
export class ScandataState {
  @Selector() static scandata(state: ScandataStateModel): ScandataModel[] {
    return state.scandata;
  }

  @Selector() static selected(state: ScandataStateModel): ScandataModel[] {
    return state.scandata.filter((model) => model.selected);
  }

  @Selector() static isLoading(state: ScandataStateModel): boolean {
    return state.isLoading;
  }

  @Selector() static filters(state: ScandataStateModel): ScandataFilters {
    return state.scandataFilters;
  }

  @Selector() static filterCount(state: ScandataStateModel): number {
    const filters = state.scandataFilters;
    return Object.keys(filters).filter((key) => {
      const value = filters[key as keyof typeof filters];
      return isDefined(value) && value.toString().length > 0;
    }).length;
  }

  @Action(UpdateScandata) updateScandata(
    ctx: StateContext<ScandataStateModel>,
    { scandata }: UpdateScandata
  ) {
    ctx.setState(patch<ScandataStateModel>({ scandata: updateScandata(scandata) }));
  }

  @Action(SetScandata) setScandata(
    ctx: StateContext<ScandataStateModel>,
    { scandata }: SetScandata
  ) {
    ctx.setState(patch<ScandataStateModel>({ scandata: scandata }));
  }

  @Action(SelectScandataModel) selectScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: SelectScandataModel
  ) {
    ctx.setState(
      patch({
        scandata: updateItem(
          (model) => model.id === scandataModelId,
          (model) => {
            return { ...model, ...{ selected: true } };
          }
        ),
      })
    );
  }

  @Action(UnselectScandataModel) unselectScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: UnselectScandataModel
  ) {
    ctx.setState(
      patch({
        scandata: updateItem(
          (model) => model.id === scandataModelId,
          (model) => {
            return { ...model, ...{ selected: false } };
          }
        ),
      })
    );
  }

  @Action(SelectOnlyOneScandataModel) selectOnlyOneScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: SelectOnlyOneScandataModel
  ) {
    const data = ctx.getState().scandata.slice();
    data.forEach((model) => (model.selected = model.id === scandataModelId));
    ctx.patchState({ scandata: data });
  }

  @Action(SelectAllScandataModels) selectAllScandataModels(ctx: StateContext<ScandataStateModel>) {
    const data = ctx.getState().scandata.slice();
    data.forEach((model) => (model.selected = true));
    ctx.patchState({ scandata: data });
  }

  @Action(UnselectAllScandataModels) unselectAllScandataModels(
    ctx: StateContext<ScandataStateModel>
  ) {
    const data = ctx.getState().scandata.slice();
    data.forEach((model) => (model.selected = false));
    ctx.patchState({ scandata: data });
  }

  @Action(PatchScandataModel) patchScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModel }: PatchScandataModel
  ) {
    this.patchScandataModels(ctx, new PatchScandataModels([scandataModel]));
  }

  @Action(PatchScandataModels) patchScandataModels(
    ctx: StateContext<ScandataStateModel>,
    { scandataModels }: PatchScandataModels
  ) {
    const updateItems = scandataModels.map((model) =>
      updateItem<ScandataModel>(
        (item) => item.id === model.id,
        (item) => ({ ...item, ...model })
      )
    );

    ctx.setState(
      patch({
        scandata: compose(...updateItems),
      })
    );
  }

  @Action(UpdateScandataModels) updateScandataModels(
    ctx: StateContext<ScandataStateModel>,
    { scandataModels }: UpdateScandataModels
  ) {
    const updateItems = scandataModels.map((model) =>
      updateItem<ScandataModel>((x) => x.id === model.id, { ...model })
    );

    ctx.setState(
      patch({
        scandata: compose(...updateItems),
      })
    );
  }

  @Action(RemoveScandataModel) removeScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: RemoveScandataModel
  ) {
    ctx.setState(
      patch({
        scandata: removeItem<ScandataModel>((item) => item.id === scandataModelId),
      })
    );
  }

  @Action(ApplyFilters) applyFilters(
    ctx: StateContext<ScandataStateModel>,
    { payload }: ApplyFilters
  ) {
    ctx.patchState({ scandataFilters: payload });
  }

  @Action(ClearFilters) clearFilters(ctx: StateContext<ClearFilters>) {
    ctx.patchState({ scandataFilters: {} });
  }

  @Action(SetIsLoading) setIsLoading(
    ctx: StateContext<ScandataStateModel>,
    { isLoading }: SetIsLoading
  ) {
    ctx.patchState({ isLoading: isLoading });
  }
}

function updateScandata(data: ScandataModel[]): StateOperator<ScandataModel[]> {
  return (existing: ExistingState<ScandataModel[]>) => {
    const updated = data.map((item) => {
      const current = existing.find((ex) => ex.id === item.id);
      const merged = isNil(current) ? item : { ...current, ...item };
      return merged;
    });
    return updated;
  };
}
