<style>
  #logs {
    white-space: pre-wrap !important; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -pre-wrap !important; /* Opera 4-6 */
    white-space: -o-pre-wrap !important; /* Opera 7 */
    word-wrap: break-word !important;
    padding: 5px;
    height: 150px;
    overflow-y: scroll;
    overflow: auto;
    display: flex;
    flex-direction: column;
    font-size: 15px;
  }
</style>

<div class="flex flex-col h-full bg-white">
  <div class="flex flex-col bg-trimble-blue-dark text-white text-center">
    <div class="my-1 font-medium">Cesium Performance Test</div>
    <div class="py-1 text-sm">Scans</div>
  </div>

  <div class="w-full relative">
    <div *ngIf="isLoading$ | async" class="w-full absolute">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </div>

  <div class="pb-3 flex-1 overflow-y-auto pt-2" cdkScrollable>
    <sd-scandata-tree-view [scandata]="(scandata$ | async)!"></sd-scandata-tree-view>
  </div>

  <div class="flex flex-col">
    <div class="py-1 text-sm bg-trimble-blue-dark text-white text-center">Tracks</div>

    <div class="flex flex-col mx-2 mb-1 mt-1">
      <div class="mt-1 pl-1 text-sm">Animation Duration</div>

      <input
        matInput
        class="shadow appearance-none border border-gray-1 rounded w-full py-1 px-3 mb-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="number"
        id="duration"
        value="5000"
        (change)="(updateDuration)"
        (input)="(updateDuration)"
        placeholder="Duration"
      />

      <button class="mb-1" (click)="addPoint()">Add Point</button>
      <button class="mb-1" (click)="removeLastPoint()">Remove Last Point</button>
      <button class="mb-1" (click)="clearTrack()">Clear Track</button>

      <button class="mb-1" (click)="trackToConsole()">Apply Track</button>
      <a class="mb-1 mt-2" [href]="LocalStorageData()">Download Tracks</a>
      <!-- <button mat-raised-button (click)="SaveAllTracksToLocalstorage()">Save To Localstorage</button> -->

      <input hidden type="file" name="inputfile" #inputFile id="inputfile" />
      <button class="mb-1" (click)="inputFile.click()">Upload Tracks</button>
    </div>

    <!-- <button mat-raised-button (click)="cameraConsole()">Camera to console</button> -->

    <div class="py-1 text-sm bg-trimble-blue-dark text-white text-center">Logs</div>
    <pre id="logs"></pre>
  </div>
</div>
