import { Route } from '@angular/router';
import { authGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { ScanViewerComponent } from './scan-viewer/scan-viewer.component';
import { CesiumResolverService } from './services/cesium-resolver.service';

export const enum Routes {
  Login = 'login',
  Logout = 'logout',
}

export const appRoutes: Route[] = [
  { path: Routes.Login, component: LoginComponent },
  { path: Routes.Logout, component: LogoutComponent },
  {
    path: '',
    component: ScanViewerComponent,
    canActivate: [authGuard],
    resolve: { accessToken: CesiumResolverService },
  },
  { path: '**', redirectTo: '' },
];
