import { ScandataFilters } from './scandata-filters.model';
import { ScandataModel } from './scandata.models';

export class UpdateScandata {
  static readonly type = '[Scandata] UpdateScandata';
  constructor(public readonly scandata: ScandataModel[]) {}
}

export class SetScandata {
  static readonly type = '[Scandata] SetScandata';
  constructor(public readonly scandata: ScandataModel[]) {}
}

export class SelectScandataModel {
  static readonly type = '[Scandata] SelectScandataModel';
  constructor(public readonly scandataModelId: string) {}
}

export class UnselectScandataModel {
  static readonly type = '[Scandata] UnselectScandataModel';
  constructor(public readonly scandataModelId: string) {}
}

export class SelectOnlyOneScandataModel {
  static readonly type = '[Scandata] SelectOnlyOneScandataModel';
  constructor(public readonly scandataModelId: string) {}
}

export class SelectAllScandataModels {
  static readonly type = '[Scandata] SelectAllScandataModels';
}

export class UnselectAllScandataModels {
  static readonly type = '[Scandata] UnselectAllScandataModels';
}

export class PatchScandataModel {
  static readonly type = '[Scandata] PatchScandataModel';
  constructor(public readonly scandataModel: Partial<ScandataModel>) {}
}

export class PatchScandataModels {
  static readonly type = '[Scandata] PatchScandataModels';
  constructor(public readonly scandataModels: Partial<ScandataModel>[]) {}
}

export class UpdateScandataModels {
  static readonly type = '[Scandata] UpdateScandataModels';
  constructor(public readonly scandataModels: ScandataModel[]) {}
}

export class RemoveScandataModel {
  static readonly type = '[Scandata] RemoveScandataModel';
  constructor(public readonly scandataModelId: string) {}
}

export class ClearFilters {
  static readonly type = '[Scandata] ClearFilters';
}

export class ApplyFilters {
  static readonly type = '[Scandata] ApplyFilters';
  constructor(public readonly payload?: ScandataFilters) {}
}

export class SetIsLoading {
  static readonly type = '[Scandata] SetIsLoading';
  constructor(public readonly isLoading: boolean) {}
}
