export enum TilesetStatus {
  NotReady = 'NotReady',
  Ready = 'Ready',
  Error = 'Error',
}

export interface Tileset {
  id: string;
  format: string;
  formatVersion: string;
  status: TilesetStatus;
  rootUrl?: string;
}
