import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Path } from '../../scan-viewer/viewer-web3d/paths';
import {
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../../scandata/scandata.models';
import {
  ScandataTreeIconComponent,
  TreeIcon,
} from '../scandata-tree-icon/scandata-tree-icon.component';

@Component({
  selector: 'sd-scandata-tree',
  standalone: true,
  imports: [CommonModule, MatIconModule, ScandataTreeIconComponent, MatMenuModule],
  templateUrl: './scandata-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeComponent {
  @Input() scandata!: ScandataModel[];

  @Output() modelIconClick = new EventEmitter<ScandataModel>();
  @Output() modelNameClick = new EventEmitter<ScandataModel>();
  @Output() modelPlayClick = new EventEmitter<ScandataModel>();


  ids(): string[] {
    const j : string[] = [];
    const paths = <Path[]>JSON.parse(localStorage.getItem("paths") ?? "[]");
    paths.forEach(x => {
      j.push(x.id)
    });
    return j;
  }


  modelIconClicked(model: ScandataModel) {
    this.modelIconClick.emit(model);
  }

  modelNameClicked(model: ScandataModel) {
    this.modelNameClick.emit(model);
  }

  modelPlayClicked(model: ScandataModel) {
    this.modelPlayClick.emit(model);
  }

  getModelIcon(model: ScandataModel): TreeIcon {
    if (
      model.loadStatus === ScandataLoadStatus.Loading ||
      model.displayStatus === ScandataDisplayStatus.AwaitingDisplay
    ) {
      return TreeIcon.loading;
    }

    if (!model.showInScene) {
      return TreeIcon.off;
    }

    if (
      model.loadStatus === ScandataLoadStatus.LoadingError ||
      model.displayStatus === ScandataDisplayStatus.DisplayError
    ) {
      return TreeIcon.error;
    }

    return TreeIcon.on;
  }
}
