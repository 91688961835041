import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export enum TreeIcon {
  on,
  off,
  error,
  loading,
}

@Component({
  selector: 'sd-scandata-tree-icon',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './scandata-tree-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeIconComponent {
  @Input() treeIcon!: TreeIcon;
  @Output() clicked = new EventEmitter<void>();
  treeIconType = TreeIcon;
}
