import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ScandataService } from '../scandata/scandata.service';
import { ViewerListComponent } from './viewer-list/viewer-list.component';
import { Web3dViewerComponent } from './viewer-web3d/viewer-web3d.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, ViewerListComponent, Web3dViewerComponent],
  templateUrl: './scan-viewer.component.html',
  styleUrls: ['./scan-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanViewerComponent {
  constructor(scandataService: ScandataService) {
    scandataService.loadScandata();
  }
}
