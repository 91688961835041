import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { ProjectRegionService } from '../project-region/project-region.service';
import { ScandataService } from '../scandata/scandata.service';
import { SetSettings } from './app.actions';
import { AppSettings } from './app.models';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private store: Store,
    private scandataService: ScandataService,
    private projectRegionService: ProjectRegionService
  ) {}

  async initialize(): Promise<void> {
    await this.initAppSettings();

    this.projectRegionService.loadProjectRegion();
  }

  async initAppSettings(): Promise<AppSettings> {
    const response: Response = await fetch('appsettings.json');
    const settings: AppSettings = await response.json();
    await firstValueFrom(this.store.dispatch(new SetSettings(settings)));
    return settings;
  }
}
