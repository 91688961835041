import { Cartesian3, Matrix4 } from '@cesiumgs/cesium-analytics';

export interface Path {
  id: string;
  name: string;
  segments: Segment[];
}

export interface TrimbleRotation {
  pitch: number;
  yaw: number;
}
/*
{
  "positionWC": {
    "x": 3477447.3692016816,
    "y": 5417978.041635723,
    "z": 288.8050883103592
  },
  "heading": 1.9725999551160411,
  "pitch": 0.6827332766633365,
  "roll": 5.310946778819423,
  "transform": {
    "0": 1,
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 1,
    "6": 0,
    "7": 0,
    "8": 0,
    "9": 0,
    "10": 1,
    "11": 0,
    "12": 0,
    "13": 0,
    "14": 0,
    "15": 1
  }
}

*/

export interface TCamera {
  positionWC: Cartesian3;
  heading: number;
  pitch: number;
  roll: number;
  transform: Matrix4;
}

export interface Segment {
  position?: Cartesian3;
  rot?: TrimbleRotation;
  time: number;
  up?: Cartesian3;
  forward?: Cartesian3;
}

export function getPaths(): Path[] {
  if(localStorage.getItem("paths")) {
    return JSON.parse(localStorage.getItem("paths") ?? "[]");
  }
  
  return [];

}
