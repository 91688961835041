<ng-container *ngIf="(busy$ | async) === true">
  <div class="flex flex-col grow justify-center items-center -translate-y-32">
    <mat-spinner class="m-2" color="primary" [diameter]="96"></mat-spinner>
    <label class="m-2">Authorizing...</label>
  </div>
</ng-container>

<ng-container *ngIf="(error$ | async) === true" class="">
  <div class="flex flex-col grow justify-center items-center -translate-y-32">
    <div>Could not log in, check back later.</div>
    <a mat-button>Ok</a>
  </div>
</ng-container>
