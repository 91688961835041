import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, map, switchMap, take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { SCANDATA_PROJECT_URL } from '../utils/scandata-project-url';
import { PatchScandataModels, SetIsLoading, UpdateScandata } from './scandata.actions';
import {
  PointcloudAPIStatus,
  PointcloudStatus,
  ScandataDisplayStatus,
  ScandataModel,
} from './scandata.models';

@Injectable({
  providedIn: 'root',
})
export class ScandataService {
  private readonly scandataProjectUrl$ = inject(SCANDATA_PROJECT_URL);

  private readonly statusMap = new Map<PointcloudAPIStatus, PointcloudStatus>([
    [PointcloudAPIStatus.Initializing, PointcloudStatus.Processing],
    [PointcloudAPIStatus.Uploaded, PointcloudStatus.Processing],
    [PointcloudAPIStatus.InProgress, PointcloudStatus.Processing],
    [PointcloudAPIStatus.Ready, PointcloudStatus.Ready],
    [PointcloudAPIStatus.Failed, PointcloudStatus.Failed],
  ]);

  constructor(private store: Store, private http: HttpClient) {}

  setDisplayStatus(scandata: ScandataModel[], displayStatus: ScandataDisplayStatus) {
    if (scandata.length === 0) {
      return;
    }

    const patchModels: Partial<ScandataModel>[] = scandata.map((item) => ({
      id: item.id,
      displayStatus,
    }));

    this.store.dispatch(new PatchScandataModels(patchModels));
  }

  loadScandata() {
    this.store.dispatch(new SetIsLoading(true));
    this.getScandata().subscribe((data) => {
      this.store.dispatch(new UpdateScandata(data));
      this.store.dispatch(new SetIsLoading(false));
    });
  }

  private getScandata(): Observable<ScandataModel[]> {
    return this.scandataProjectUrl$.pipe(
      take(1),
      switchMap((url) => this.http.get<ScandataModel[]>(`${url}/pointclouds?filterBy=`)),
      map((models) => models.map((model) => this.mapScandataModel(model))),
      // This should only be done once for 3d extension, so can't include in mapScandataModel
      map((scans) => scans.map((scan) => this.updateScanForWeb3d(scan))),
      map((scans) => scans.sort((a, b) => a.name.localeCompare(b.name)))
    );
  }

  private mapScandataModel(scandataModel: ScandataModel): ScandataModel {
    this.assignScandataModelStatus(scandataModel);
    this.convertScandataModelDates(scandataModel);

    return scandataModel;
  }

  private assignScandataModelStatus(scandataModel: ScandataModel) {
    scandataModel.pointcloudStatus = this.statusMap.get(scandataModel.status) as PointcloudStatus;
  }

  private convertScandataModelDates(scandataModel: ScandataModel) {
    if (isDefined(scandataModel.captureDatetimeUtc))
      scandataModel.captureDatetimeUtc = new Date(scandataModel.captureDatetimeUtc);

    if (isDefined(scandataModel.uploadedDate))
      scandataModel.uploadedDate = new Date(scandataModel.uploadedDate);
  }

  private updateScanForWeb3d(scan: ScandataModel) {
    scan.web3dId = uuidv4();
    return scan;
  }
}
