import { InjectionToken, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, combineLatest, filter, map, take } from 'rxjs';
import { AppState } from '../app-state/app.state';

export const SCANDATA_PROJECT_URL = new InjectionToken<Observable<string>>('ScandataProjectUrl', {
  factory: () => {
    const store = inject(Store);
    return combineLatest([
      store.select(AppState.projectRegion),
      store.select(AppState.settings),
    ]).pipe(
      filter(([region, settings]) => isDefined(region) && isDefined(settings)),
      map(([region, settings]) => `${region.scandata.url}projects/${settings.connectProject}`),
      take(1)
    );
  },
});
