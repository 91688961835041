import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, catchError, combineLatest, filter, map, switchMap } from 'rxjs';

import { SetProjectRegion } from '../app-state/app.actions';
import { AppState } from '../app-state/app.state';
import { AuthState } from '../auth/auth.state';
import { ProjectRegion } from '../project-region/project-region.models';

interface ProjectRegionDto {
  sdsRegionCode: string;
  sdsBaseUrl: string;
  featuresUrl: string;
  featureTilesBaseUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectRegionService {
  constructor(private store: Store, private http: HttpClient) {}

  loadProjectRegion() {
    const projectsUrl = this.store.selectSnapshot(AppState.settings).endpoints.projects.url;

    combineLatest([this.store.select(AppState.settings), this.store.select(AuthState.loggedIn)])
      .pipe(
        filter(([settings, loggedIn]) => !!settings && loggedIn),
        switchMap(([settings]) => {
          return this.http.get<ProjectRegionDto>(`${projectsUrl}/${settings.connectProject}`).pipe(
            catchError((err: unknown) => {
              //todo: handle error 404, add add project tenancy
              console.error('Error getting project url', err);
              return EMPTY;
            })
          );
        }),
        map((projectRegionDto) => {
          const projectRegion: ProjectRegion = {
            scandata: {
              url: projectRegionDto.sdsBaseUrl,
              protected: true,
            },
            features: {
              url: projectRegionDto.featuresUrl,
              protected: true,
            },
          };

          return projectRegion;
        }),
        switchMap((projectRegion) => this.store.dispatch([new SetProjectRegion(projectRegion)]))
      )
      .subscribe();
  }
}
