import { isDefined, isNil } from '../common';

export interface FormUrlEncodeOptions {
  undefinedValue: 'excluded' | 'undefined' | '';
  nullValue: 'excluded' | 'null' | '';
  propertyNotation: '.' | '[]';
}

const formUrlEncodeDefaults: FormUrlEncodeOptions = {
  undefinedValue: 'excluded',
  nullValue: 'excluded',
  propertyNotation: '.',
};

export function formUrlEncode(
  data: { [s: string]: unknown },
  options?: Partial<FormUrlEncodeOptions>
) {
  const opts: FormUrlEncodeOptions = { ...formUrlEncodeDefaults, ...options };
  const flatMap = flatMapKeyValueStrings(data, opts);
  const params = new URLSearchParams(flatMap);
  return params.toString();
}

function flatMapKeyValueStrings(
  data: { [s: string]: unknown },
  options: FormUrlEncodeOptions
): { [s: string]: string } {
  if (isNil(data)) return {};
  const { undefinedValue, nullValue } = options;
  const result = Object.entries(data).reduce((acc, [key, value]) => {
    if (/string|number|boolean/.test(typeof value)) {
      acc[key] = String(value);
    } else if (value instanceof Date) {
      // https://tc39.es/ecma262/multipage/numbers-and-dates.html#sec-date.prototype.tojson
      acc[key] = value.toJSON();
    } else if (Array.isArray(value)) {
      Object.assign(acc, reduceArray(key, value, options));
    } else if (value instanceof Set) {
      Object.assign(acc, reduceArray(key, Array.from(value), options));
    } else if (typeof value === 'object' && value) {
      Object.assign(acc, reduceObject(key, value, options));
    } else if (value === undefined && undefinedValue !== 'excluded') {
      acc[key] = undefinedValue;
    } else if (value === null && nullValue !== 'excluded') {
      acc[key] = nullValue;
    }
    return acc;
  }, {} as { [s: string]: string });

  return result;
}

function reduceArray(key: string, arr: Array<unknown>, options: FormUrlEncodeOptions) {
  if (!arr.length) return { [`${key}`]: '' };

  const { undefinedValue, nullValue } = options;
  const reduced = arr
    .filter((value) => {
      if (value === undefined && undefinedValue !== 'excluded') return true;
      if (value === null && nullValue !== 'excluded') return true;
      return isDefined(value);
    })
    .reduce((acc: { [s: string]: unknown }, curr, index) => {
      acc[`${key}[${index}]`] = curr;
      return acc;
    }, {} as { [s: string]: unknown });
  return flatMapKeyValueStrings(reduced, options);
}

function reduceObject(key: string, obj: object, options: FormUrlEncodeOptions) {
  const reduced = Object.entries(obj).reduce((acc: { [s: string]: unknown }, [k, v]) => {
    options.propertyNotation === '.' ? (acc[`${key}.${k}`] = v) : (acc[`${key}[${k}]`] = v);
    return acc;
  }, {} as { [s: string]: unknown });
  return flatMapKeyValueStrings(reduced, options);
}
