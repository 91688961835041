export enum AppContext {
  ConnectExtension = 'ConnectExtension',
  ConnectIntegrated = 'ConnectIntegrated',
  Standalone = 'Standalone',
  Unknown = 'Unknown',
}

export interface Endpoint {
  url: string;
  protected: boolean;
}

export type Endpoints = {
  [key in 'connect' | 'projects' | 'regions' | 'features']: Endpoint;
};

export interface AppSettings {
  title: string;
  production: boolean;
  appContext: AppContext.ConnectIntegrated | AppContext.Standalone;
  endpoints: Endpoints;
  baseUrl: string;
  tidSettings: TidSettings;
  connectProject: string;
}

export interface TidSettings {
  app_name: string;
  client_id: string;
  redirect_uri: string;
  post_logout_redirect_uri: string;
  issuer: string;
  authorization_endpoint: string;
  token_endpoint: string;
  userinfo_endpoint: string;
  revocation_endpoint: string;
  end_session_endpoint: string;
  tenantDomain: string;
}
