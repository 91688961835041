import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AppService } from './app-state/app.service';
import { AppState } from './app-state/app.state';
import { appRoutes } from './app.routes';
import { AuthState } from './auth/auth.state';
import { tokenInterceptor } from './auth/token.interceptor';
import { ScandataState } from './scandata/scandata.state';
import { windowProvider } from './services/window.provider';
import { TilesetState } from './tileset/tileset.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    importProvidersFrom(
      MatDialogModule,
      NgxsModule.forRoot([AppState, AuthState, ScandataState, TilesetState], {
        selectorOptions: {
          // These Selector Settings are recommended in preparation for NGXS v4
          // (See https://www.ngxs.io/concepts/select#joining-selectors for their effects)
          suppressErrors: false,
          injectContainerState: false,
        },
      }),
      NgxsStoragePluginModule.forRoot({
        key: [AuthState],
      })
    ),
    windowProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppService],
      multi: true,
    },
  ],
};

function initializeAppFactory(appService: AppService) {
  return () => appService.initialize();
}
