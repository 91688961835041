export type BinarySearchCompareFn<T, U> = (
  itemToFind: U,
  itemToCompare: T,
  array: T[],
  i: number
) => number;

function binarySearchCompareFn<T, U>(itemToFind: U, itemToCompare: T) {
  const a = String(itemToFind);
  const b = String(itemToCompare);
  return a === b ? 0 : a < b ? -1 : 1;
}

export function binarySearchIndex<T, U>(
  array: T[],
  itemToFind: U,
  compareFn: BinarySearchCompareFn<T, U> = binarySearchCompareFn
): number {
  let mid = -1;
  let left = 0;
  let right = array.length - 1;
  let compared = 0;

  while (left <= right) {
    mid = left + Math.floor((right - left) / 2);
    compared = compareFn(itemToFind, array[mid], array, mid);

    if (compared === 0) return mid;
    if (compared < 0) right = mid - 1;
    if (compared > 0) left = mid + 1;
  }

  const result = compared < 0 ? -(mid + 1) : -(mid + 2);
  return result;
}

export function binarySearchItem<T, U>(
  array: T[],
  itemToFind: U,
  compareFn: BinarySearchCompareFn<T, U> = binarySearchCompareFn
): T | undefined {
  const index = binarySearchIndex(array, itemToFind, compareFn);
  const value = array[index];
  return value;
}
